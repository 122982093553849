<template>
    <v-container>
        <v-row class="d-flex flex-column align-center">
            <v-col cols="12" class="d-flex flex-column align-center">
                <v-img 
                    :src="'/assets/branding/' + (($vuetify.theme.dark) ? 'verifyHiResDark.png' : 'verifyHiResLight.png')"
                    height="210"
                    contain
                />
                <h2 class="mt-n12">Verify Costing</h2>
            </v-col>
            <v-col cols="6" class="d-flex flex-column align-center mt-3">
                <v-row>
                    <v-col cols="auto">
                        <v-text-field
                            type="number"
                            v-model="input"
                            label="Enter gross media plan / uplift cost"
                            outlined
                            style="width: 200px;"
                        />
                    </v-col>

                    <span class="mt-6" style="font-size: 20px;">=</span>

                    <v-col cols="auto">
                        <v-text-field
                            disabled
                            readonly
                            :value="total"
                            label="Verify Cost Total"
                            outlined
                            style="width: 200px;"
                        />
                    </v-col>
                </v-row>

                <v-row class="text--bold">
                    <v-col cols="12">
                        <h4>Please Note:</h4>
                    </v-col>
                    <v-col cols="12">
                        <ul>
                            <li class="text--bold">All costs are net unless otherwise stated.</li>
                            <li>Media plan uplift costs should be costed and a verify cost added to the uplift should Verify be required.</li>
                            <li>Costs include creative trafficking & verification reporting through Verify (powered by DOOH.com).</li>
                            <li>Verify does not currently cover media plans with a value less than £50,000.</li>
                            <li>Costs cover up to 8 pieces of content per format. Please note media owners have their own additional costs for multiple pieces of copy and these costs are not included in Verify costs.</li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" class="d-flex flex-column align-center mt-3">
                <v-img 
                    :src="'/assets/branding/' + (($vuetify.theme.dark) ? 'poweredByDoohDark2.png' : 'poweredByDoohLight2.png')"
                    height="50"
                    contain
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import roundNumber from '@/mixins/helpers/roundNumber'

export default {
    mixins: [roundNumber],
    
    data: () => ({
        input: 0,
    }),

    computed: {
        total: {
            get() {
                let multiplier = 0

                if (this.input >= 50000 && this.input <= 200000)
                    multiplier = 0.008
                else if (this.input >= 200001 && this.input <= 500000)
                    multiplier = 0.0075
                else if (this.input >= 500001)
                    multiplier = 0.0065

                return this.roundMaxDecimals(this.input * multiplier, 2)
            }
        }
    }
}
</script>
