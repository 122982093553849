<template>
    <v-container>
        <VerifyCalculatorAdminBar />
        <VerifyCalculatorCostingInterface />
    </v-container>
</template>

<script>
import VerifyCalculatorAdminBar from '@/components/verify/calculator/AdminBar'
import VerifyCalculatorCostingInterface from '@/components/verify/calculator/CostingInterface'

export default {
    components: {
        VerifyCalculatorAdminBar,
        VerifyCalculatorCostingInterface
    }
}
</script>