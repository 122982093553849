<template>
    <v-container class="d-flex justify-end" >
        <v-btn @click="copyLink('https://verify.dooh.com/#/verify-costing')">Generate Public Link</v-btn>
    </v-container>
</template>

<script>
export default {
    methods: {
        async copyLink(value) {
            await navigator.clipboard.writeText(value)
            this.$root.$emit('snackbarSuccess', '"' + value + '" copied to clipboard.')
        },
    }
}
</script>